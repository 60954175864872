import React from "react";
// import logo from "./images/airbnb-logo.png"


export default function Navbar(){
    return(
        <div>
            <div className="nav-bar">

                <img src="./images/airbnb-logo.png" alt="logo" width="82px;"/>

            </div>
           






        </div>
    )
}